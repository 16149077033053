
import { useQuery } from '@apollo/client';
import { useEffect, useState, useContext } from 'react';
import Dictionary from "../Queries/Dictionary";
import dashboardContext from "../context/dashboard";
import sort from '../assets/icons/sort.svg'
const History = () => {
    const { selectedUser, role, studentsResult } = useContext(dashboardContext)
    const { data: historyResult, loading, error } = useQuery(Dictionary.getHistory,
        { variables: { authorId: role === 'writer' ? studentsResult?.id : selectedUser ? selectedUser : studentsResult[0]?.id } })
    const [history, setHistory] = useState()
    const [historyData, setHistoryData] = useState()
    const [loadContent, setLoadContent] = useState(false)
    const [reversed, setReversed] = useState(true)
    useEffect(() => {
        if (!error && !loading) {
            setHistory(historyResult.history)
            const hdata = [...historyResult.history.data].reverse()
            setHistoryData(hdata)
        }
        setLoadContent(false);
    }, [historyResult, loading, error])

    if (loading || !history)
        return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>Loading....</div>
    return (
        <div style={{ backgroundColor: "#ffffff", padding: "10px 10px", height: '100%' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h3>Chat History</h3>
            </div>
            <div style={{ border: "1px solid #5C84A8", margin: "-10px 0" }}></div>
            {loadContent ?
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>Loading....</div>
                : Object.keys(history).length === 0 ?
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>No chat history</div>
                    :
                    <div className="table-container">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th onClick={() => setReversed(!reversed)}><div style={{ display: 'flex', alignItems: 'center', width: '25%' }}>Request<img style={{ marginLeft: 30 }} src={sort} alt='sort' width={20} height={20} /></div></th>
                                    <th>Response</th>
                                </tr>
                            </thead>
                            <tbody>
                                {reversed ?

                                    historyData.map((item, index) => {
                                        if(item.role === 'user')
                                            return (
                                                <tr key={index} >
                                                    <td>{item.content}</td>
                                                    <td>{historyData[index - 1].content}</td>
                                                </tr>
                                            )
                                    })
                                    :
                                    history.data.map((item, index) => {
                                        if(item.role === 'user')
                                            return (
                                                <tr key={index} >
                                                    <td>{item.content}</td>
                                                    <td>{history.data[index + 1].content}</td>
                                                </tr>
                                            )
                                    }
                                )}
                            </tbody>
                        </table>
                    </div>}
        </div >
    );
}

export default History;